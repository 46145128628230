<template>
  <div class="about">

    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
            404 - Seite nicht gefunden
          </h1>
          <h2 class="subtitle is-italic has-text-centered">
            Er aber sprach: Selig sind, die das Wort Gottes hören und bewahren. Lukas 11,28
          </h2>
        </div>
      </div>
    </section>


    <section class="section">
      <div class="container">
        <p class="content has-text-left">
          Ups, da ging etwas schief. Diese Seite existiert leider nicht.
          <br/><br/>
          Schau doch mal hier vorbei:<br/><br/>
          - <a href="/about">Über uns</a><br/>
          - <a href="/veranstaltungen">Veranstaltungen</a>
        </p>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: 'NotFoundPage',
  metaInfo() {
    return {
      title: "Christliche Gemeinde Karlsruhe - 404 Nicht gefunden",
      meta: [
        { name: 'robots', content:  'noindex'},
      ]
    }
  }
}
</script>
