<template>
    <div id="app">

        <Menu></Menu>

        <div style="height:150px">
        </div>
        <router-view/>

    </div>
</template>


<script>
    // @ is an alias to /src
    import Menu from '@/components/Menu.vue'

    export default {
        name: 'menuComponent',
        components: {
            Menu
        }
    }
</script>

<style lang="css">
    @font-face {
        font-family: 'Kaushan Script';
        src: url('assets/fonts/KaushanScript-Regular.woff2') format('woff2'),
        url('assets/fonts/KaushanScript-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato Thin';
        src: url('assets/fonts/Lato-Thin.woff2') format('woff2'),
        url('assets/fonts/Lato-Thin.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato';
        src: url('assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('assets/fonts/Lato-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato';
        src: url('assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('assets/fonts/Lato-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }

</style>

<style lang="scss">
    // Import Bulma's core
    @import "~bulma/sass/utilities/_all";

    // Set your colors
    $primary: #ffc20e;

    $body-background-color: #fffaec;

    $family-sans-serif: 'Lato';
    $family-primary: 'Lato';

    // Setup $colors to use as bulma classes (e.g. 'is-twitter')
    $colors: (
            "primary": ($primary, $white),
            "link": (#faa61a, #FFFFFF)
    );

    $navbar-item-img-max-height: 4rem;
    $navbar-background-color: #fffaec;
    $navbar-height: 4rem;

    // Import Bulma and Buefy styles
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
</style>


