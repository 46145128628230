<template>

    <div class="card tile is-child">
        <div class="card-image">
            <figure class="image is-4by3" v-on:click="extendVeranstaltung">
                <img :src="getImgUrl(image)"/>
            </figure>
        </div>
        <div class="card-content">
            <div class="media">

                <div class="media-content">
                    <p class="title is-4">{{titel}}</p>
                    <p class="subtitle is-6">{{beginn | dayFilter("dddd, DD.MM.YYYY")}} <br> {{beginn |
                        dayFilter("HH.mm")}} Uhr</p>

                </div>
            </div>

            <div class="content has-text-left">

                <p v-on:click="awesome=true" v-if="!awesome">
                    {{beschreibung | truncate(120, '...')}}
                    <a>Weiterlesen</a>

                </p>


                <div v-if="awesome">
                    <p v-html="beschreibung">
                    </p>
                    <div style="margin-top:10px">
                        <p class="title is-5">Ort</p>
                        <p class="subtitle is-6">{{ort}}</p>
                    </div>


                    <div class="media" style="margin-top:40px; margin-left:-35px">
                        <div class="media-left">
                            <figure class="image is-64x64">
                                <img class="is-rounded" :src="getAnsprechpartnerImg(ansprechpartnerBild)">
                            </figure>
                        </div>
                        <div class="media-content">
                            <p class="title is-4">{{ansprechpartner}}</p>
                            <p class="subtitle">
                                <a class="button is-link" v-if="!mailForm" v-on:click="showKontaktformular(!mailForm)">E-Mail schreiben</a>
                            </p>
                        </div>
                    </div>
                    <Kontaktformular :empfaenger=ansprechpartnerMail :showKontaktFormular=mailForm
                                     @show-kontaktformular="showKontaktformular"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Kontaktformular from '@/components/Kontaktformular.vue'

    export default {
        name: 'KalenderVeranstaltung',
        components: {Kontaktformular},
        props: {
            titel: String,
            beschreibung: String,
            beginn: String,
            ende: String,
            image: String,
            ansprechpartner: String,
            ansprechpartnerBild: String,
            ansprechpartnerMail: String,
            ort: String
        },
        data() {
            return {
                awesome: false,
                mailForm: false
            }
        },
        methods: {
            extendVeranstaltung() {
                if(this.awesome) {
                    this.awesome=false;
                } else {
                    this.awesome=true;
                }
            },
            showKontaktformular(showMailmForm) {
                this.mailForm = showMailmForm;
            },
            getImgUrl(pic) {
                return pic;
            },
            getAnsprechpartnerImg(pic) {
                return require('../assets/ansprechpartner/' + pic);
            }
        }
    }
</script>


